import { Component } from '@angular/core';
import { UniperAuthService } from '../../services/uniperAuth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css'] // Optional: You can create a CSS file for styling
})
export class ForbiddenComponent {

  constructor(private authService: UniperAuthService, private router: Router) { }

  goBackToHome() {
    if (this.authService.hasOnlyInventoryAccess) {
      this.router.navigateByUrl('/ppa-inventory')
      return;
    } else {
      this.router.navigateByUrl('/dashboard')
      return;
    }
  }
}
