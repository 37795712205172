import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AggregatedScenarioResultIcon,
  AnalyticsSandboxIcon,
  AnalyticsSandboxMetadataIcon,
  CalculatorIcon,
  CheckCircleIcon,
  DealTermIcon,
  EscalatedForwardCurveAsOfDateIcon,
  EscalatedForwardCurveReferenceIcon,
  ExecuteValuationTaskIcon,
  ForwardCurveReferenceIcon,
  ForwardCurveRevisionIcon,
  ImportIcon,
  ModelCalibrationIcon,
  ModelResultIcon,
  ShieldExclamationIcon,
  TimeSeriesAnalyticalSandboxDataPurgeIcon,
  TimeSeriesIcon,
  ValuationRunResultsIcon,
  ValuationTaskIcon,
  VolatilityReferenceIcon,
  XCircleIcon,
  HistoricDatasetIcon,
  CircleIconProcessing,
  TableCellsIcon,
  PPAAssetIcon,
  PPABusinessPartnerIcon,
  PPADefaultIcon,
  PPAGenerationUnitIcon,
  PPAMaLoIcon,
  PPADealIcon,
  PPATransactionIcon,
  PPAContractIcon,
  PPAMarketParticipantIcon,
  TabNavLinkIcon,
  PPADealGroupIcon
} from './icons.component';

const declarationsAndExports = [
  DealTermIcon,
  TimeSeriesIcon,
  AnalyticsSandboxIcon,
  AnalyticsSandboxMetadataIcon,
  ModelCalibrationIcon,
  VolatilityReferenceIcon,
  ForwardCurveReferenceIcon,
  PPAAssetIcon,
  PPABusinessPartnerIcon,
  PPAContractIcon,
  PPAMarketParticipantIcon,
  PPADealGroupIcon,
  PPADefaultIcon,
  TabNavLinkIcon,
  PPAMaLoIcon,
  PPADealIcon,
  PPAGenerationUnitIcon,
  PPATransactionIcon,
  ValuationTaskIcon,
  ExecuteValuationTaskIcon,
  ModelResultIcon,
  ValuationRunResultsIcon,
  ForwardCurveRevisionIcon,
  TimeSeriesAnalyticalSandboxDataPurgeIcon,
  EscalatedForwardCurveReferenceIcon,
  EscalatedForwardCurveAsOfDateIcon,
  ImportIcon,
  CheckCircleIcon,
  XCircleIcon,
  AggregatedScenarioResultIcon,
  ShieldExclamationIcon,
  CalculatorIcon,
  HistoricDatasetIcon,
  CircleIconProcessing,
  TableCellsIcon,
];

@NgModule({
  declarations: [...declarationsAndExports],
  exports: [...declarationsAndExports],
  imports: [CommonModule],
})
export class IconsModule {}
